.form-box {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 19.5rem;
  gap: 1rem;

  border-radius: 1rem;
  background: var(--popup-bg-active);
  box-shadow: var (--box-shadow);

  .logo {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    .title {
      color: var(--text);
      font: var(--font-extra-bold) 1.5rem var(--font-family-secondary);
    }
  }

  .message {
    color: var(--text-secondary);
    font: var(--font-semi-bold) 1rem var(--font-family);

    .player {
      min-height: 0.6rem;
      font-weight: var(--font-bold);
    }
  }

  .form {
    gap: 1rem;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
  }
}
