.input-text {
  display: inline-flex;
  padding: 0.25rem 1rem;
  justify-content: center;
  align-items: center;

  border: 0;
  border-radius: 0.5rem;
  background: var(--white-btn-bg);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

  color: var(--text);
  font: var(--font-bold) 1rem var(--font-family);
  text-align: center;
}
