.p-card-box {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .label {
    padding-top: 0.2rem;
    color: var(--text);
    text-align: center;
    font: var(--font-extra-bold) 0.75rem var(--font-family-secondary);

    &.small {
      color: var(--text);
    }
  }
}

.p-card {
  background: var(--primary-btn-bg-hover);
  border-radius: 1rem;
  width: 5.875rem;
  height: 8.75rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.small {
    border-radius: 0.5rem;
    width: 3rem;
    height: 4.5rem;
  }

  &.active {
    background: var(--primary-btn-bg);
  }

  &:hover {
    background: var(--primary-btn-bg);
  }

  &:hover.active {
    background: var(--primary-btn-bg-hover);
  }

  &.place {
    cursor: initial;

    background: var(--place-back);
    box-shadow: var(--box-shadow);
  }

  .back {
    cursor: initial;

    border-radius: 0.5rem;
    border: 1px solid var(--card-back-border);
    width: 3.8rem;
    height: 6.8rem;

    &.small {
      border-radius: 0.3rem;
      width: 1.7rem;
      height: 3.2rem;
    }
  }

  .number {
    color: var(--text-number);
    text-align: center;
    text-shadow: var(--text-shadow);
    font: var(--font-bold) 3rem var(--font-family-secondary);

    &.small {
      font-size: 1.5rem;
    }
  }
}
