.poll-header {
  display: inline-flex;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  flex-direction: column;
  gap: 0.25rem;

  background: var(--popup-bg-active);
  box-shadow: var(--box-shadow-out);

  width: 100%;

  @media (min-width: 576px) {
    width: 360px;
  }

  .timer {
    color: var(--text-secondary);
    font: 1rem var(--font-family);
    display: inline-flex;

    .text {
      flex: 1;
    }
  }

  .task-name {
    color: var(--text);
    font: var(--font-bold) 1.25rem var(--font-family);
    line-height: 1.75rem;
  }

  .actions {
    display: inline-flex;

    .start-stop,
    .share,
    .exit {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      color: var(--text);
      border: 1px solid var(--secondary-btn-border);
      padding: 0.25rem 0.5rem;

      cursor: pointer;
    }

    .start-stop {
      flex: 1 0 0;
      border-radius: 0.5rem 0rem 0rem 0.5rem;
      background: var(--primary-btn-bg);

      &:hover {
        background: var(--primary-btn-bg-hover);
      }
      &:active {
        background: var(--primary-btn-bg);
      }

      .label {
        color: var(--text-white);
        font: var(--font-semi-bold) 1rem var(--font-family);
        flex: 1;
      }
    }

    .share,
    .exit {
      width: 4rem;
      background: var(--secondary-btn-bg);

      &:hover {
        background: var(--white-btn-bg);
      }
      &:active {
        background: var(--secondary-btn-bg);
      }
    }

    .exit {
      border-radius: 0rem 0.5rem 0.5rem 0rem;
    }
  }
}
