.table-cards {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: center;
  align-content: flex-start;
  padding: 1rem;
  gap: 1rem;

  @media (min-width: 576px) {
    max-width: 650px;
    padding: 2rem 0;
  }
}
