:root {
  --background: #CCD4DE;
  --primary-btn-border: #511515;
  --primary-btn-bg: #7d2121;
  --primary-btn-bg-hover: #511515;
  --primary-btn-text: #fff;
  --card-back-border: #aa3939;
  --place-back: #34354d;
  --white-btn-bg: #fff;
  --text: #333;
  --text-secondary: #666;
  --text-white: #fff;
  --text-number: #e2e2e2;
  --secondary-btn-border: #999;
  --secondary-btn-bg: #e4e4e4;
  --popup-bg-active: #f1f1f1;
  --popup-bg: #d4d4d4;
}
