.button {
  display: inline-flex;
  padding: 0.38rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  border-radius: 0.5rem;
  border: 1px solid var(--primary-btn-border);
  background: var(--primary-btn-bg);
}

.button:hover {
  background: var(--primary-btn-bg-hover);
}
.button:active {
  background: var(--primary-btn-bg);
}

.button.white {
  border-color: var(--secondary-btn-border);
  background: var(--white-btn-bg);
}
.button.white:hover {
  background: var(--secondary-btn-bg);
}
.button.white:active {
  background: var(--white-btn-bg);
}

.button.icon {
  padding: .8rem;
}

.button.secondary {
  border-color: var(--secondary-btn-border);
  background: var(--secondary-btn-bg);
}
.button.secondary:hover {
  background: var(--white-btn-bg);
}
.button.secondary:active {
  background: var(--secondary-btn-bg);
}

.label {
  color: var(--primary-btn-text);
  font: var(--font-bold) 1rem var(--font-family);
  text-align: center;
}

.white .label  {
  font-weight: var(--font-semi-bold);
  color: var(--text);
}

.secondary .label  {
  font-weight: var(--font-semi-bold);
  color: var(--text);
}

.icon img  {
  font-weight: var(--font-semi-bold);
  color: var(--primary-btn-text);
}