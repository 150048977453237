:root {
  --box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  --box-shadow-out: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  --text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --font-family: Cairo;
  --font-family-secondary: Roboto;
  --font-semi-bold: 600;
  --font-bold: 700;
  --font-bold-x: 700;
  --font-extra-bold: 900;
  font-size: 16px;
}

html,
body,
.root,
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--background);
  display: flex;
  margin: 0;
  /* overflow: hidden; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 1rem var(--font-family);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.screen-center {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
