.alert-popup {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--place-back);
  transition: 3s ease-out;

  &.visible {
    display: flex;
    opacity: 0.95;
    transition: 3s ease-out;
  }

  .box {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 19.5rem;
    gap: 1rem;
    top: -50px;
    position: relative;
    opacity: 1 !important;

    border-radius: 1rem;
    background: var(--popup-bg-active);
    box-shadow: var (--box-shadow);

    font: 1rem var(--font-family-secondary);
  }

  .header {
    font-weight: var(--font-semi-bold);
  }
  .body {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
  .footer {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
  }
}
