.popup {
  display: inline-flex;

  padding: 0.6rem 1.5rem 1rem;
  flex-direction: column;
  align-items: center;

  border-radius: 1rem 1rem 0rem 0rem;
  background: var(--popup-bg);
  box-shadow: var(--box-shadow-out);
  gap: 0.5rem;
  

  &.active {
    background: var(--popup-bg-active);
  }

  width: 100%;

  @media (min-width: 576px) {
    width: auto;
  }

  .text {
    display: inline-flex;
    flex-direction: row;
    color: var(--text);
    font: var(--font-semi-bold) 1rem var(--font-family);

    .player {
      min-height: 0.6rem;
      font-weight: var(--font-bold);
    }
  }

  .cards-box {
    display: flex;
    align-items: center;
    align-self: stretch;

    &.result {
      align-self: center;
    }

    @media (max-width: 576px) {
      margin-right: -1.5rem;
    }

    .cards {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      overflow: auto;

      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
    }
  }
}
